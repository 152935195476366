<template>
	<div class="plan-loc">
		<van-nav-bar
      fixed
      title="Plan Name"
      left-arrow
      @click-left="goBack">
    </van-nav-bar>
		<div class="nav-bar"></div>
		<div class="address-map" id="map"></div>
		<div class="store-address-content">
			<div v-for="(addressRouteItem, addressRouteIndex) in visitPlanDetail" :key="addressRouteIndex" class="plan-store-info">
				<div class="store-item">
					<div class="store-name txt-ellipsis">{{ addressRouteItem.customerName }}</div>
					<div class="store-address txt-ellipsis">{{ addressRouteItem.address }}</div>
				</div>
				<van-icon size="23" name="list-switch" />
			</div>
		</div>
		<div class="address-num">
			<div>{{ visitPlanDetail.length }} locations</div>
			<van-icon size="26" name="guide-o" @click="goToVisit" />
		</div>
	</div>
</template>

<script>
import { arsVisitPlanDetailApi } from '@/api/plan'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
let $this = ""
var geocoder = null
function initMap() {
	console.log('visitPlanDetail', $this.visitPlanDetail)
	const map = new google.maps.Map(document.getElementById("map"), {
		zoom: 4,
		center: { lat: parseFloat($this.visitPlanDetail[0].lat) || 37.775, lng: parseFloat($this.visitPlanDetail[0].lng) || -122.434 }, // Australia. { lat: 37.775, lng: -122.434 }
		streetViewControl: false, // 隐藏街景小人
		// setControlVisibility: true
		mapTypeControl: false
    // fullscreenControl: false, // 是否隐藏全屏功能
    // zoomControl: false, // 是否隐藏放大所有功能
	});
	const directionsService = new google.maps.DirectionsService();
	const directionsRenderer = new google.maps.DirectionsRenderer({
		draggable: true,
		map,
		panel: document.getElementById("panel"),
	});

	// 添加标记和信息窗口
	// $this.visitPlanDetail.forEach((item, index) => {
  //       const marker = new google.maps.Marker({
  //           position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
  //           map: map,
  //           title: item.name || '1235' // 假设每个地点有一个'name'属性作为标记的标题
  //       });

  //       const infowindow = new google.maps.InfoWindow({
  //           content: `<h3>1234}</h3><p>123456</p>`
  //       });

  //       marker.addListener('click', () => {
  //           infowindow.open(map, marker);
  //       });
  //   });
	
	directionsRenderer.addListener("directions_changed", () => {
		const directions = directionsRenderer.getDirections();

		if (directions) {
			computeTotalDistance(directions);
		}
	});
	let originVal = $this.visitPlanDetail[0].address || ''
	let destinationVal = $this.visitPlanDetail[1] ? $this.visitPlanDetail[1].address : $this.visitPlanDetail[0].address
	displayRoute(
		originVal, // "5542 York Blvd, Los Angeles, CA 90042, United States",
		destinationVal, // "4463 Beverly Blvd Unit A, Los Angeles, CA 90004, United States",
		directionsService,
		directionsRenderer,
	)
}
function displayRoute(origin, destination, service, display) {
	let waypointsList = []
	$this.visitPlanDetail.forEach((item, index) => {
    if (index > 1) {
			waypointsList.push({location: item.address})
		}
	})
	service
		.route({
			origin: origin,
			destination: destination,
			waypoints: waypointsList,
			// waypoints: [
			// 	{ location: "2355 Westwood Blvd, Los Angeles, CA 90064, United States" },
			// 	{ location: "154 S Vermont Ave, Los Angeles, CA 90004, United States" },
			// ],
			travelMode: google.maps.TravelMode.DRIVING,
			avoidTolls: true,
		})
		.then((result) => {
			console.log('123', result)
			display.setDirections(result);
		})
		.catch((e) => {
			alert("Could not display directions due to: " + e);
		});
}
function computeTotalDistance(result) {
			let total = 0;
			const myroute = result.routes[0];

			if (!myroute) {
				return;
			}

			for (let i = 0; i < myroute.legs.length; i++) {
				total += myroute.legs[i].distance.value;
			}

			total = total / 1000;
			// document.getElementById("total").innerHTML = total + " km";
		}
export default {
	data() {
		return {
			visitPlanDetail: [],
			paramsUrl: {},
			currentAddres: ''
		}
	},
	created() {
    $this = this
		this.paramsUrl = this.$route.query
	},
	deactivated(){
		this.$destroy()
	},
	mounted() {
		console.log('mounted')
		geocoder = new google.maps.Geocoder()
		this.getVisitPlanDetail()
		this.getCurrentLoc()
	},
	methods: {
		getVisitPlanDetail() {
			arsVisitPlanDetailApi(this.paramsUrl.planId).then(res => {
        console.log('res', res)
				this.visitPlanDetail = res.data
		    window.initMap()
			})
		},
		goBack () {
			this.$router.go(-1)
		},
		goToVisit() {
			console.log('currentAddres')
			if (this.visitPlanDetail.length == 0) {
				return
			}
			console.log('this.visitPlanDetail', this.visitPlanDetail)
			let detailLen = this.visitPlanDetail.length
			let origin = this.currentAddres || this.visitPlanDetail[0].address
			let destination = this.visitPlanDetail[detailLen - 1].address
			let waypoints = ''
			this.visitPlanDetail.forEach((res, index) => {
				if (index < detailLen - 1) {
					if (index < detailLen - 2) {
						waypoints += res.address + '|'
					} else {
						waypoints += res.address
					}
				}
			})
			console.log('origin', origin)
			console.log('destination', destination)
			console.log('waypoints', waypoints)
			window.location.href = `https://www.google.com/maps/dir/?api=1&dir_action=navigate&origin=${origin}&destination=${destination}&waypoints=${waypoints}`
			// var paramsUrl = `https://www.google.com/maps/dir/?api=1&dir_action=navigate&origin=${origin}&destination=${destination}&waypoints=${waypoints}`
			// console.log('paramsUrl', paramsUrl)
			// this.$router.push(`/plan/mapRoute?url=${encodeURIComponent(paramsUrl)}`)
		},
		getCurrentLoc() {
			if (navigator.geolocation) {
				var pos = {}
        navigator.geolocation.getCurrentPosition(
          (position) => {
						console.log('position', position)
            pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
              console.log('pos', pos)
							this.getlocation(pos.lat, pos.lng)
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          },
        );
      }
		},
		getlocation(lat, lng) {
			console.log('getlocation')
			let $that = this
			// 根据经纬度获取位置
			geocoder.geocode({
				location: new google.maps.LatLng(lat, lng)
			}, function geoResults(results, status) {
				//这里处理结果和上面一模一样
				if (status == google.maps.GeocoderStatus.OK) {
					// alert('地理反解析结果：' + );
					// results[0].formatted_address
					// console.log(results)
					// results[0].geometry.location
					// centerPoint.address = results[0].formatted_address
					// centerPoint.results = results
					// map.setCenter(centerPoint)
					// document.getElementById('postMessage').click()
					console.log('results', results)
					$that.currentAddres = results[0].formatted_address
				} else {
					alert("：error " + status);
				}
			});
		}
	}
}
window.initMap = initMap;
</script>

<style lang="less" scoped>
.plan-loc {
	font-size: 12px;
	.nav-bar {
    margin-top: 46px;
  }
	.address-map {
		height: 55vh;
	}
	.store-address-content {
		// height: 170px;
		height: calc(100vh - 55vh - 128px);
		overflow: auto;
	}
	.plan-store-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 13px;
		border-bottom: 1px solid #eeeeee;
		.store-item {
			flex: 1;
		}
		.store-name {
			width: 326px;
			margin-bottom: 5px;
			font-size: 14px;
			font-weight: bold;
		}
		.store-address {
			width: 326px;
			color: #007bff;
		}
	}
	.address-num {
		position: fixed;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 20px 25px;
		color: #007bff;
	}
}

</style>